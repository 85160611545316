import React from 'react';
import Education from '../content/Education';
import RythmRoadtrip from '../content/RythmRoadtrip'
import Home from '../content/Home'
import Projects from '../content/Projects';
import Activities from '../content/Activities';
import RythmRoadtripSuccess from '../content/RythmRoadtripCallback';
import Songle from '../content/songle'
import WebLayout from './WebLayout' 
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import '../App.css';

export default function PersonalApp(){
    return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<WebLayout/>}>
          <Route index element={<Home />} />
          <Route path="/education" element={<Education />} ></Route>
          <Route path="/activities" element={<Activities />}></Route>
          <Route path="/projects" element={<Projects />}></Route>
          <Route path="/rythmroadtrip" element={<RythmRoadtrip />}></Route>
          <Route path="/songle" element={<Songle/>}></Route>
          <Route path='rythmroadtrip/success'element={<RythmRoadtripSuccess />}></Route>
        </Route>
      </Routes>
    </BrowserRouter>
   
  );
}
    

import React from 'react';
import Popup from 'reactjs-popup';
import { IconContext } from "react-icons";
import { SlInfo } from "react-icons/sl";
import './SongleInfo.css'
import { Button } from 'react-bootstrap';

export default function SongleInfo(props){
    const code = window.localStorage.getItem('authCode');
    return (
        <Popup 
        trigger=
                {<Button className='info-button'>
                    <IconContext.Provider value={{ color: "white"}}>
                        <div>
                            <SlInfo/>
                        </div>
                    </IconContext.Provider>
                </Button>} 
                modal nested>
                {
                    close => (
                        <div className='content'>
                            <h1>Songle</h1>
                            <p>Welcome to songle! Guess one of your top spotify songs in 6 tries. You will recieve the following hints:</p>
                            <li>AI generated image that was propted with the songs lyrics</li>
                            <li>The Genre of the song</li>
                            <li>The artist of the song</li>
                            <li>The album the song appears in</li>
                            <p>Green indicates a character in the correct position, yellow indicates a character in the song and grey indicates a character not in the word.</p>
                            
                            <div>
                               { code !== null 
                               ? <Button onClick=
                                    {() => close()}>
                                        Close
                                </Button> 
                                : <Button onClick={() => props.spotifyEnpoint()}>
                                        Autorize and play!
                                </Button>}
                            </div>
                        </div>
                    )
                }
            </Popup>
    );
}

import React, { useState, useEffect } from 'react';
import { Container, Col, Row } from 'react-bootstrap';
import SpotifyAuthRequest from '../components/SpotifyAuthRequest.js'
import "./Songle.css";
import ClipLoader from "react-spinners/ClipLoader";
import SongleInfo from '../components/SongleInfo.js';
import ErrorPage from './ErrorPage.js';


export default function Songle() {
    const [guesses, setGuesses] = useState(Array(6).fill("")); 
    const [currentGuess, setCurrentGuess] = useState("");
    const [isGameOver, setIsGameOver] = useState(false);
    const [currentRow, setCurrentRow] = useState(0);
    const [game, setGame] = useState({});
    const [boxSize, setBoxSize] = useState(0);
    const [codeChallenge, setCodeChallenge] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);


    
    function generateRandomString(length) {
        let text = '';
        const possible =
        'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    
        for (let i = 0; i < length; i++) {
        text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    }
      
    async function generateCodeChallenge(codeVerifier) {
        const digest = await crypto.subtle.digest(
        'SHA-256',
        new TextEncoder().encode(codeVerifier),
        );
    
        return btoa(String.fromCharCode(...new Uint8Array(digest)))
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
    }
      
      
    function redirectToSpotifyAuthorizeEndpoint() {
        const codeVerifier = generateRandomString(64);
        generateCodeChallenge(codeVerifier).then((code_challenge) => {
        window.localStorage.setItem('code_verifier', codeVerifier);
        setCodeChallenge(code_challenge);
        });

    }
    
    useEffect(() => {    


      const playGame = async () => {
        setLoading(true);
        const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://rythmresume-backend-14a7187116ea.herokuapp.com";
        fetch(`${endpoint}/get-game-data?accessToken=${window.localStorage.getItem('access_token')}`, {
        }).then((res) => {
          if(res.status !== 200){
            throw new Error(); 
          }
          return res.json()
        }).then((data) =>{
          window.localStorage.setItem("game", JSON.stringify(data))
          window.localStorage.setItem("guesses", JSON.stringify((Array(6).fill(""))));
          window.localStorage.setItem("currentGuess", JSON.stringify(""));
          window.localStorage.setItem("isGameOver", JSON.stringify(false));
          window.localStorage.setItem("currentRow", JSON.stringify(0));
          setLoading(false);
          setGame(data);
        }).catch((e) => {
          setError(true);
        });
      }

      const getUser = () => {
        const storedGame = JSON.parse(window.localStorage.getItem("game"));
        if(storedGame){
          const gameTime = new Date(parseInt(storedGame.time));
          const now = new Date();
          if (sameDay(now, gameTime)) {
            const storedGuesses =  JSON.parse(window.localStorage.getItem("guesses"));
            const storedCurrentGuess =  JSON.parse(window.localStorage.getItem("currentGuess"));
            const storedIsGameOver = JSON.parse(window.localStorage.getItem("isGameOver"));
            const storedCurrentRow = JSON.parse(window.localStorage.getItem("currentRow"));
            setGuesses(storedGuesses);
            setCurrentGuess(storedCurrentGuess);
            setIsGameOver(storedIsGameOver);
            setCurrentRow(storedCurrentRow);
            setGame(storedGame);
          }else{
            playGame();
          }
        }else{
          playGame();
        }
      }

      let authCode = window.localStorage.getItem('authCode');
      let refreshToken = window.localStorage.getItem('refresh_token');
      let firstLoad = window.localStorage.getItem('firstLoad');
      const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://rythmresume-backend-14a7187116ea.herokuapp.com";
      if(firstLoad !== "true" && refreshToken !== null){
        const refresh = window.localStorage.getItem('refresh_token');
        fetch(`${endpoint}/refresh`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ refresh }),
        }).then((res) => res.json()).then((data) => {
            window.localStorage.setItem('access_token', data.accessToken);
            window.localStorage.setItem('refresh_token', data.refreshToken);
            getUser();
        });
      }else if(authCode !== null){
        getUser();
        window.localStorage.removeItem('firstLoad');
      }
      fetch(`${endpoint}/accessToken`, {
          method: 'POST',
          
        })
    }, [])

    useEffect(() => {
      if(codeChallenge !== ''){
        const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://rythmresume-backend-14a7187116ea.herokuapp.com";
        let codeVerifier = window.localStorage.getItem('code_verifier');
        fetch(`${endpoint}/auth`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({ codeChallenge, codeVerifier }),
        }).then((res) => res.json()).then((data) => {
          window.location.href = data.authUrl;
        });
      }
    }, [codeChallenge])

    // Calculate box size based on the word length
    useEffect(() => {
      if(game.name != null){
          const maxBoxSize = 50; // Maximum size for short words
          const minBoxSize = 1; // Minimum size for long words
          const totalBoxes = game.name.length; // Total number of boxes
          const availableWidth = window.innerWidth * 0.65; // Space available for the word boxes (65% of screen)
          let idealBoxSize = Math.min(maxBoxSize, Math.max(minBoxSize, availableWidth / totalBoxes));
          setBoxSize(idealBoxSize);
      }
      

    }, [game])


    useEffect(() => {
      const clearBoard = () => {
        window.localStorage.setItem("isGameOver", JSON.stringify(true));
        window.localStorage.setItem("currentRow", JSON.stringify(currentRow + 1));
        setIsGameOver(true);
        setCurrentRow(currentRow + 1);
      }

      const handleKeyPress = (e) => {
        if (isGameOver) return;
        const key = e.key.toUpperCase();
        if (key === "ENTER" && currentGuess.length === game.name.length) {
          const newGuesses = [...guesses];
          newGuesses[currentRow] = currentGuess;
          window.localStorage.setItem("guesses", JSON.stringify(newGuesses));
          setGuesses(newGuesses);
          window.localStorage.setItem("currentGuess", JSON.stringify(""));
          setCurrentGuess("");
          if (currentGuess.toLocaleUpperCase() === game.name.toLocaleUpperCase() || currentRow === 5) {
            clearBoard();
          } else {
            window.localStorage.setItem("currentRow", JSON.stringify(currentRow + 1));
            setCurrentRow(currentRow + 1);
          }
        } else if (key === "BACKSPACE") {
          //TODO
          const newGuesses = [...guesses];
          newGuesses[currentRow] = currentGuess.slice(0, -1);
          window.localStorage.setItem("guesses", JSON.stringify(newGuesses));
          setGuesses(newGuesses);
          window.localStorage.setItem("currentGuess", JSON.stringify(currentGuess.slice(0, -1)));
          setCurrentGuess(currentGuess.slice(0, -1));
        } else if (/^[A-Z0-9]$/.test(key) && currentGuess.length < game.name.length) {
          let tempGuess = currentGuess + key;
          if(isNotLetterOrNumber(game.name.charAt(tempGuess.length))){
            let stop = false
            while(tempGuess.length < game.name.length && !stop){
              if(isNotLetterOrNumber(game.name.charAt(tempGuess.length))){
                tempGuess = tempGuess + game.name.charAt(tempGuess.length);
              }else{
                stop = true

              }

            }
          }
          const newGuesses = [...guesses];
          newGuesses[currentRow] = tempGuess;
          window.localStorage.setItem("guesses", JSON.stringify(newGuesses));
          setGuesses(newGuesses);
          window.localStorage.setItem("currentGuess", JSON.stringify(tempGuess));
          setCurrentGuess(tempGuess);
        }
      };

  
      window.addEventListener("keydown", handleKeyPress);
      return () => window.removeEventListener("keydown", handleKeyPress);
    }, [currentGuess, currentRow, isGameOver, game, guesses]);


    const getBoxColor = (letter, index, rowIndex) => {
      if (!guesses[currentRow - 1]?.[index] || rowIndex >= currentRow) return "";
      if (game.name[index].toLocaleUpperCase() === letter.toLocaleUpperCase()) return "green";
      if (game.name.toLocaleUpperCase().includes(letter.toLocaleUpperCase())) return "yellow";
      return "gray";
    };


    const sameDay = (d1, d2) => {
        return d1.getFullYear() === d2.getFullYear() &&
               d1.getMonth() === d2.getMonth() &&
               d1.getDate() === d2.getDate();
    }
    function isNotLetterOrNumber(char) {
      return !/^[a-zA-Z0-9]$/.test(char);
    }

    return (
      <Container fluid className="game-layout bg-dark">
        <div className='game-title-container'>
            <h1 className='game-title'>Songle</h1>
            <p className='game-title-p'>Powered by</p>
            <img src='SpotifyLogo.png' alt='Spotify Logo' className='game-title-logo'></img>
            <SongleInfo spotifyEnpoint={redirectToSpotifyAuthorizeEndpoint}></SongleInfo>
         </div>
        {error === true ? <ErrorPage></ErrorPage> : window.localStorage.getItem('access_token') === null ? <SpotifyAuthRequest spotifyEnpoint={redirectToSpotifyAuthorizeEndpoint}></SpotifyAuthRequest> 
        :loading ? <div
          className='bg dark'
          style={{display:"flex", alignItems:"center", justifyContent:"center", width:"100%", height:"100%"}}
          ><ClipLoader
              loading={loading}
              color='white'
              size={150}
              aria-label="Loading Spinner"
              data-testid="loader"
            /></div>
        : boxSize === 0 ? <div></div> 
        : <Row>
          <Col md={4} xs={12} className="image-text-section">
            <img 
              src={`data:image/png;base64, ${game.image}`} 
              alt="Songle Game Hint" 
              className="song-image"
            />

          </Col>
          
          {/* Wordle Game Section */}
          <Col md={8} xs={12} className="wordle-section">
            {guesses.slice(0, 6).map((guess, rowIndex) => (
              <div key={rowIndex} className="guess-row">
                {game.name.split("").map((letter, letterIndex) =>
                  letter === " " ? (
                    <div key={letterIndex} style={{ width: `${boxSize/2}px`, height: `${boxSize}px` }}></div>
                  ) : isNotLetterOrNumber(letter) ? 
                    <div
                      key={letterIndex}
                      className="non-box"
                      style={{ width: `${boxSize/5}px`, height: `${boxSize}px`, fontSize: `2em` }}
                    >{letter}</div> : (
                    <div
                      key={letterIndex}
                      className={`box ${getBoxColor(guess[letterIndex], letterIndex, rowIndex)}`}
                      style={{ width: `${boxSize}px`, height: `${boxSize}px` }}
                    >
                      {guess[letterIndex] || ""}
                    </div>
                  )
                )}
              </div>
            ))}
            {isGameOver && <div className="game-over">{`Game Over: ${game.name}`}</div>}
          </Col>
          <div className="hints-container">
              {currentRow > 0 && (
                <div className="hint-row">
                  <p className="hint-title">Genres:</p>
                  <div className="hint-values">
                    {game.genres.map((genre, index) => (
                      <p key={index} className="hint-item">{genre}</p>
                    ))}
                  </div>
                </div>
              )}
              {currentRow > 1 && (
                <div className="hint-row">
                  <p className="hint-title">Artists:</p>
                  <div className="hint-values">
                    {game.artists.map((artist, index) => (
                      <p key={index} className="hint-item">{artist}</p>
                    ))}
                  </div>
                </div>
              )}
              {currentRow > 2 && (
                <div className="hint-row">
                  <p className="hint-title">Album:</p>
                  <p className="hint-item">{game.album}</p>
                </div>
              )}
            </div>
        </Row>}
      </Container>
    );
}

import React, { useState, useEffect } from 'react';
import { Container, Form, Button, Col, Row } from 'react-bootstrap';
import TrackComponent from '../components/TracksComponent';
import ArtistComponent from '../components/ArtistComponent';
import RecentlyPlayed from '../components/RecentlyPlayed';
import ErrorPage from './ErrorPage.js';
import SpotifyAuthRequest from '../components/SpotifyAuthRequest.js'
import './RythmRoadtrip.css'

export default function RythmRoadtrip(){
  const [type, setType] = useState('artists');
  const [timeRange, setTimeRange] = useState('medium_term');
  const [limit, setLimit] = useState(20);
  const [artists, setArtists] = useState([]);
  const [tracks, setTracks] = useState([]);
  const [recentlyPlayed, setRecentlyPlayed] = useState([]);
  const [accessToken, setAccessToken] = useState("");
  const [codeChallenge, setCodeChallenge] = useState('');
  const [error, setError] = useState(false)

  
  function generateRandomString(length) {
      let text = '';
      const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  
      for (let i = 0; i < length; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
      }
      return text;
  }
    
  async function generateCodeChallenge(codeVerifier) {
      const digest = await crypto.subtle.digest(
      'SHA-256',
      new TextEncoder().encode(codeVerifier),
      );
  
      return btoa(String.fromCharCode(...new Uint8Array(digest)))
      .replace(/=/g, '')
      .replace(/\+/g, '-')
      .replace(/\//g, '_');
  }
    
    
  function redirectToSpotifyAuthorizeEndpoint() {
      const codeVerifier = generateRandomString(64);
      generateCodeChallenge(codeVerifier).then((code_challenge) => {
      window.localStorage.setItem('code_verifier', codeVerifier);
      setCodeChallenge(code_challenge);
      });

  }
  


  useEffect(() => {    
    let authCode = window.localStorage.getItem('authCode');
    let refreshToken = window.localStorage.getItem('refresh_token');
    let firstLoad = window.localStorage.getItem('firstLoad');
    const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://rythmresume-backend-14a7187116ea.herokuapp.com";
    if(firstLoad !== "true" && refreshToken !== null){
      const refresh = window.localStorage.getItem('refresh_token');
      fetch(`${endpoint}/refresh`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh }),
      }).then((res) => res.json()).then((data) => {
          window.localStorage.setItem('access_token', data.accessToken);
          window.localStorage.setItem('refresh_token', data.refreshToken);
          fetchRecenltyPlayed(data.accessToken);
      });
    }else if(authCode !== null){
      fetchRecenltyPlayed(window.localStorage.getItem('access_token'));
      window.localStorage.removeItem('firstLoad');
    }
    fetch(`${endpoint}/accessToken`, {
        method: 'POST',
        
      }).then((res) => res.json()).then((data) => {
        setAccessToken(data.accessToken);
    })
  }, [])

  useEffect(() => {
    if(codeChallenge !== ''){
      const endpoint = process.env.NODE_ENV === "development" ? "http://localhost:8000" : "https://rythmresume-backend-14a7187116ea.herokuapp.com";
      let codeVerifier = window.localStorage.getItem('code_verifier');
      fetch(`${endpoint}/auth`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',

          },
          body: JSON.stringify({ codeChallenge, codeVerifier }),
      }).then((res) => res.json()).then((data) => {
        window.location.href = data.authUrl;
      });
    }
  }, [codeChallenge])


  const fetchRecenltyPlayed = async (token) =>{
    if(token){
      let date = Date.now();
      fetch('https://api.spotify.com/v1/me/player/recently-played?limit=5&before=' + date, {
      headers: { 
        "Authorization": `Bearer ${token}`
      }
    }).then((res) => res.json()).then((data) =>{
        let tempTracks = [];
        data.items.forEach((item) => {
            tempTracks.push({
                id: item.track.id,
                name: item.track.name,
                artists: item.track.artists.map((a) => a.name),
                images: item.track.album.images,
                url: item.track.external_urls.spotify, 
                playedAt: item.played_at
            })
        });
        setRecentlyPlayed(tempTracks);
    }).catch((e) => {
      setError(true)
    })
    }
  }


  const fetchTracksDetails = async (idString) => {
    fetch('https://api.spotify.com/v1/tracks?ids=' + idString, {
        headers: { "Authorization": "Bearer " + accessToken}
    }).then(res => res.json()).then((data) => {
        let tempTracks = [];
        data.tracks.forEach((track) => {
            tempTracks.push({
                id: track.id,
                name: track.name,
                artists: track.artists.map((a) => a.name),
                images: track.album.images,
                url: track.external_urls.spotify
            })
        })
        setArtists([]);
        setTracks(tempTracks);
    });
  }
  const fetchArtistsDetails = async (idString) => {
    fetch('https://api.spotify.com/v1/artists?ids=' + idString, {
        headers: { "Authorization": "Bearer " + accessToken}
    }).then(res => res.json()).then((data) => {
        let tempArtists = [];
        console.log(data)
        data.artists.forEach((artist) => {
            tempArtists.push({
                id: artist.id,
                name: artist.name,
                url: artist.external_urls.spotify,
                images: artist.images,
                followers: artist.followers.total
            })
        });
        setTracks([]);
        setArtists(tempArtists);
    });
  }


  const handleArtists = async (e) => {
    e.preventDefault();
    // Handle the form submission logic
    fetch('https://api.spotify.com/v1/me/top/artists?time_range=' + timeRange + '&limit=' + limit + '&offset=0', {
        method: 'GET',
        headers: { 
            'Authorization' : `Bearer ${window.localStorage.getItem('access_token')}`,
        },
    }).then((result) =>result.json()).then((data) => {
        let idString = "";
        data.items.forEach((artist) => {
            idString = idString + artist.id + ",";
        });
       fetchArtistsDetails(idString.slice(0, -1));
    })

  };
  const handleTracks = (e) => {    
    e.preventDefault();
    // Handle the form submission logic
    fetch('https://api.spotify.com/v1/me/top/tracks?time_range=' + timeRange + '&limit=' + limit + '&offset=0', {
        method: 'GET',
        headers: { 
            'Authorization' : `Bearer ${window.localStorage.getItem('access_token')}`
        },
    }).then((result) =>result.json()).then((data) => {
        let idString = ""
        data.items.forEach((track) => {
            idString = idString + track.id + ","
        });
       fetchTracksDetails(idString.slice(0, -1));
    })
  };

  return (<Container className="mt-5">
    <div>
      <h1 className='white-text'>Rythm Roadtrip</h1>
        <p className='white-text'>
          Welcome to my Rhythm Roadtrip! Below are your most recently played songs. Play with the history section to see your music taste. 
        </p>
    </div>
    <div>
      { error === true ? <ErrorPage/> : window.localStorage.getItem('access_token') == null ? <SpotifyAuthRequest spotifyEnpoint={redirectToSpotifyAuthorizeEndpoint}></SpotifyAuthRequest> : 
      <div>
          <div>
        <h3 className="mb-4 white-text">Recently Played</h3>
        <RecentlyPlayed tracks={recentlyPlayed} />
      </div>
      <div className="mt-6">
        <h3 className="mb-4 white-text">Your Music History</h3>
        <Form onSubmit={type === "artists" ? handleArtists : handleTracks}>
          <Row className="align-items-end">
            <Col xs={12} md={3} className="mb-3">
              <Form.Group>
                <Form.Label>Type</Form.Label>
                <Form.Control
                  as="select"
                  value={type}
                  onChange={(e) => setType(e.target.value)}
                  required
                >
                  <option value="artists">Artists</option>
                  <option value="tracks">Songs</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={12} md={3} className="mb-3">
              <Form.Group>
                <Form.Label>Time Range</Form.Label>
                <Form.Control
                  as="select"
                  value={timeRange}
                  onChange={(e) => setTimeRange(e.target.value)}
                >
                  <option value="long_term">Long Term (1 year)</option>
                  <option value="medium_term">Medium Term (6 months)</option>
                  <option value="short_term">Short Term (4 weeks)</option>
                </Form.Control>
              </Form.Group>
            </Col>

            <Col xs={12} md={2} className="mb-3">
              <Form.Group>
                <Form.Label>Count</Form.Label>
                <Form.Control
                  type="number"
                  value={limit}
                  onChange={(e) => setLimit(e.target.value)}
                  min="1"
                  max="50"
                  required
                />
              </Form.Group>
            </Col>

            <Col xs={12} md={2} className="mb-3">
              <Button variant="primary" type="submit" className="w-100">
                Submit
              </Button>
            </Col>
          </Row>
        </Form>
        {tracks.length > 0 ? tracks.map((track, index) => <TrackComponent key={index} track={track} />) 
          : artists.length > 0 ? artists.map((artist, index) => <ArtistComponent key={index} artist={artist}/>) 
          : <></>}
      </div>
      </div>}
    </div>
  </Container>
  );

}
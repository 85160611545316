import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import './Projects.css'
import 'bootstrap/dist/css/bootstrap.min.css';
import {projects} from '../objects.js';


export default function Projects(){
  const [projectIdx, setProjectIndex] = useState(-1)

  const folderStyle = {
    width: '60vmin',
    height: '60vmin',
    backgroundColor: '#A3AAAE',
    borderRadius: '48px',
    display: 'ruby',
    padding: 20,
    overflow: 'hidden',
  };

  const widgetStyle = {
    width: '60vmin',
    height: '60vmin',
    backgroundColor: '#A3AAAE',
    borderRadius: '48px',
    padding: 20
  };
  const widgetTitle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20
  }
  const widgetTitleText = {
    display: "flex",
    flexDirection: "column",
  }
  const pageContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
  }
  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    margin: 100,
  }
  const appStyle = {
    width: '12vmin',
    height: '12vmin',
    borderRadius: 18,
    marginLeft: '3.0vmin',
    marginRight: '3.0vmin',

  }
  const appContainer = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  }

  return (
    <div style={pageContainerStyle}>
      <div style={containerStyle}>
        <div style={folderStyle}>
          {
            projects.map((p, index) => (
              <div style={appContainer} key={index}>
                <Image src={p.img} style={appStyle} className='blur-image' onMouseEnter={() => setProjectIndex(index)} onMouseLeave={() => setProjectIndex(-1)} onClick={() => {
                  window.location.href = p.url;
                }}
                ></Image>
                <p>{p.name}</p>
              </div>
            ))
          }
        </div>
        <h1 className="white-text">Projects</h1>

      </div>
      <div style={containerStyle}>
        <div style={widgetStyle}>
            {
              projectIdx >=0 ? 
              <div>
                <div style={widgetTitle}>
                  <Image src={projects[projectIdx].img} style={appStyle} className='blur-image' onClick={() => {
                    window.location.href = projects[projectIdx].url;
                  }}
                  ></Image>
                  <div style={widgetTitleText}>
                    <h2>{projects[projectIdx].name}</h2>
                    <p>{projects[projectIdx].title}</p>
                  </div>
                </div> 
                <div>{projects[projectIdx].description}</div>
                <b>Click to see more!</b>
              </div>
              : 
              <div></div>
            }
        </div>
      </div>
    </div>
  );
}